import { useState, useEffect } from 'react';
import { format } from 'date-fns'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";
import usePipeline from "common/pipelines/usePipeline";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CampaignIcon from '@mui/icons-material/Campaign';
import { formatPhoneNumber } from 'common/utils';
import Vendor from 'common/models/vendor'
import Order from 'common/models/order';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import './styles.scss';
const TableContainerStyled = styled(TableContainer)(css`
  .MuiTableCell-head .MuiSvgIcon-root {
    color: #5a5a5b
  }
  min-height: 300px;
`);

const TableBodyStyled = styled(TableBody)(css`
  th {
    padding-left: 16px !important;
  }
`);
const TableCellStyled = styled(TableCell)(css`
  border: 0px !important;
  padding: 6px 4px;
  font-size: 14px;

  .MuiStep-root {
    margin-top: 16px;
  }
  .MuiStepLabel-label {
    font-size: 10px;
    padding-top: 4px;
    margin-top: 4px !important;
  }
`);

const OrderDescriptions = styled(Typography)(css`
  font-size: 12px;
`);

const OrderDetailsBox = styled(Box)(css`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`);

const OrderLabel = styled(Typography)(css`
  font-size: 10px;
  padding-top: 4px;
  font-weight:500
`);

const OrderData = styled(Typography)(css`
  font-size: 12px;
  padding-top: 4px;
  font-weight:550
`);

const OrderContent = styled(Typography)(css`
  display: inline-block;
`);

export default function FirmAssignmentPage({ orderid, partid, setPartid, closeModal, setSelectedCompanyId }) {
  const [data, setData] = useState([])
  const [vendorIndex, setVendorIndex] = useState(0)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarType, setSnackbarType] = useState('success')
  const [orderDetails, setOrderDetails] = useState({})
  const [alertContent, setAlertConent] = useState('')

  const order = (new Order);

  useEffect(async () => {
    let result = await order.getAvailableLawFirms(orderid);
    setData(result.lawfirms);

    const orderDetails = {}

    result = await order.getDetails(orderid);

    if (result.order) {
      orderDetails['due_date'] = result.order.due_date;
      orderDetails['escrow_num'] = result.order.escrow_num;
      orderDetails['address'] = `${result.order.propertyaddress} ${result.order.propertyaddress2}, ${result.order.propertycity}, ${result.order.propertystate} ${result.order.propertyzipcode}`
    }

    result = await order.getParts(orderid);
    if (result.parts) {
      for (let p of result.parts) {
        if (p.partid == partid) {
          orderDetails['type'] = p.part_title
          break
        }
      }
    }
    setOrderDetails(orderDetails)
  }, []);


  const onAssign = async (idx) => {
    const result = await order.assignOrderPartToLawFirm(partid, data[idx].companyid);
    if (result.status == 'success') {
      setAlertConent('Successfully Assigned')
      setSnackbarType('success')
      closeModal();
      const result = await order.getParts(orderid);
      setData(result);
    }
    else {
      setShowSnackbar(true)
      setAlertConent('Assign Failure')
      setSnackbarType('error')
    }
  }

  const formatDate = (date) => {
    let fdate = ""
    try {
      fdate = format(new Date(date), 'MM/dd/yyyy')
    }
    catch (e) {}
    return fdate
  }

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (<div className="firmAssign">
 <div className="orderDetailSection">
    <Grid container>
      <Grid item xs={3} >
        <OrderLabel>
        Type
        </OrderLabel>
        <OrderData>
        {orderDetails.type}
        </OrderData>
      </Grid>
      <Grid item xs={3} >
        <OrderLabel>
        ADDRESS
        </OrderLabel>
        <OrderData>
        {orderDetails.address}
        </OrderData>
      </Grid>
      <Grid item xs={3} >
        <OrderLabel>
        DUE DATE
        </OrderLabel>
        <OrderData>
        {orderDetails.due_date && formatDate(orderDetails.due_date)}
        </OrderData>
      </Grid>
      <Grid item xs={3} >
        <OrderLabel>
        ESCROW NUMBER
        </OrderLabel>
        <OrderData>
        {orderDetails.escrow_num && formatDate(orderDetails.escrow_num)}
        </OrderData>
      </Grid>
    </Grid>
    </div>
    <div className='tableconnect'>
    <Typography variant="h4" component="h4" style={{ paddingTop: "32px" }}>
      Available Vendors
    </Typography>
    <TableContainerStyled>
      <Table sx={{ minWidth: 650 }} aria-label="">
        <TableHead className="tableHeader">
          <TableRow>
          <TableCell >Law Firm</TableCell>
            <TableCell>Attorney</TableCell>
            <TableCell >Phone Number</TableCell>
            <TableCell >Email</TableCell>
            <TableCell >Active Order</TableCell>
            <TableCell >% sla</TableCell>
            <TableCell ></TableCell>
          </TableRow>
        </TableHead>
        <TableBodyStyled>
          {data && data.map((row, idx) => (
            <TableRow
              key={row.userid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellStyled >{row.compname}</TableCellStyled>
              <TableCellStyled>
                {row.firstname} {row.lastname}
              </TableCellStyled>
              <TableCellStyled >{row.phone && formatPhoneNumber(row.phone)}</TableCellStyled>
              <TableCellStyled  color="primary"><Link href="#">{row.email}</Link></TableCellStyled>
              <TableCellStyled >{row.active_orders}</TableCellStyled>
              <TableCellStyled >{row.percent_on_time}</TableCellStyled>
              <TableCellStyled ><Button sx={{borderRadius:'20px'}} startIcon={<PersonAddIcon />} variant="outlined" onClick={() => {setPartid(partid);setSelectedCompanyId(data[idx].companyid)}}>Assign</Button></TableCellStyled>
            </TableRow>
          ))}
        </TableBodyStyled>
      </Table>
    </TableContainerStyled>
     </div>
    {/*<AttorneyConfirmationModal open={showConfirmationModal} closeModal={closeConfirmationModal} onUpdate={onUpdate} vendor_fee={data[vendorIndex]} due_date={orderDetails.due_date}/>*/}
    {/*<Snackbar open={showSnackbar} autoHideDuration={4000} onClose={closeSnackbar}>
      <Alert onClose={closeSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
        {alertContent}
      </Alert>
    </Snackbar>*/}
  </div>);
}
